<nav class="navbar navbar-expand-lg" style="background-color: #fafafa">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse d-flex justify-content-center"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav align-content-between">
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/inicio"
            [routerLinkActive]="'active'"
            aria-current="page"
            >Inicio</a
          >
        </li>
        <li class="nav-item" *ngFor="let menu of menus">
          <a class="nav-link" (click)="setCompnay(menu)" routerLink="{{menu.route}}" routerLinkActive="active">
            {{ menu.menuName }}
          </a>
        </li>
      </ul>
      <!-- <cart-button></cart-button> -->
    </div>
  </div>
</nav>
