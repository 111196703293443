<div class="cart-button cart me-2 position-relative">
  <div class="button-container position-relative p-1" (click)="goToCart()">
    <img class="" src="../../../../assets/img/cart.png" width="100%" alt="" />
    <span class="badge text-bg-danger" *ngIf="carts && carts.length > 0 && totalItems > 0">{{ this.totalItems }}</span>
  </div>
  <cart-list-companies
    class="animate__animated animate__fadeInUp"
    [ngClass]="{ animate__fadeOutDown: !showMenu, show: showMenu }"
    (onCliked)="OnClicledChild($event)"
    *ngIf="first"
  ></cart-list-companies>
</div>
