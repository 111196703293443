<div class="product-card">
  <div class="card">
    <div class="image-container">
      <img src="{{ item.urlImage }}" alt="{{ item.title }}" />
    </div>
    <h5 class="card-title text-center">{{ item.title }}</h5>
    <div class="card-body text-center">
      <p class="card-description" [innerHTML]="item.descripcion"></p>
      <div class="card-price p-2">
        <span>Precio:</span> <span>$ {{ item.total | number : ".2-2" }}</span>
      </div>
      <div class="mt-2 text-center">
        <button class="btn btn-dark me-4" (click)="sendCart()">Comprar <i class="fa-solid fa-cart-shopping"></i></button>
        <button class="btn btn-dark ms-1 px-4" (click)="see()">Ver<i class="fa-solid fa-eye ms-2"></i></button>
      </div>
    </div>
  </div>
</div>
