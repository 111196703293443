<div class="sub-navbar">
  <ul class="sub-navbar-nav me-auto py-2 mb-lg-0">
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/retazos"
        routerLinkActive=""
        aria-current="page"
        href="#"
        >Inicio</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/retazos/tienda"
        routerLinkActive="active-sub"
        >Tienda</a
      >
    </li>
  </ul>
</div>

