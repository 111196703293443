<sub-header></sub-header>
<div class="gobBack-bar display-5 ps-3"> <button class="btn btn-light px-4" (click)="gobBack()"> <i class="fa-solid fa-chevron-left"  ></i></button> </div>
<div class="container mt-1 p-5">
  <div class="row">
    <div class="col-md-6">
      <div class="img-select-wrapper">
        <div class="img-select">
          <img class="product-img" src="{{ product.urlImage }}" alt="" />
        </div>
      </div>
    </div>
    <div class="col-md-6 align-content-center">
      <div class="info">
        <p class="card-title">{{ product.title | uppercase }}</p>
        <rating></rating>
        <p class="card-price">
          <span>$ {{ product.total | number : ".2-2" }}</span>
        </p>
        <p class="card-description">Descripción</p>
        <p class="card-description" [innerHTML]="product.descripcion"></p>
      </div>
      <div class="actions text-center">
        <button class="btn btn-inf shadow px-5 show" (click)="gobBack()" ><i class="fa-solid fa-chevron-left"></i> Regresar</button>
        <button class="btn btn-dark shadow px-5 ms-2" (click)="sendCart()" >Comprar <i class="fa-solid fa-cart-shopping"></i></button>
      </div>
    </div>
  </div>
</div>
