import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/services/data/data.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(

  ) {

  }
  ngOnInit() {
  }

}
