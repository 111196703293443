<div class="header bg-body-tertiary pt-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2 col-md-1">
        <img src="../../../../assets/img/icon.png" width="100%" alt="" />
      </div>

      <div class="col text-center  pt-md-4 title">
        <div class="input-group">
          <input
            type="text"
            class="form-control input-search"
            aria-label="Dollar amount (with dot and two decimal places)"
            placeholder="buscar.."
          />
          <span class="input-group-text icon-search"><i class="fa-solid fa-magnifying-glass"></i></span>
        </div>
      </div>

      <div class="col-2 col-md-1">
        <cart-button></cart-button>
      </div>
    </div>
  </div>
</div>
