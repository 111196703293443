
<owl-carousel-o [options]="options">
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  <ng-template carouselSlide class="">
    <div class="p-2">
      <img src="../../../../assets/img/cafe_2.jpg" alt="">
    </div>
  </ng-template>
  
</owl-carousel-o>

